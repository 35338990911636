<template>
  <section class="container mb-12">
    <div class="bg-[#EDFFED] rounded-3xl px-4 py-8 md:p-8">
      <div class="text-center">
        <h2
          class="font-bold text-3xl md:text-5xl font-header-1"
        >
          Why <span class="text-primary-green">Terra Learning?</span>
        </h2>
        <p class="text-[#898989] text-sm md:text-base mt-2">
          Get started from scratch and gain hands-on experience with our
          internship and bootcamp program.
        </p>

        <div class="grid md:grid-cols-3 gap-8 md:w-4/5 mx-auto mt-8 md:mt-12">
          <div class="cursor-pointer bg-white rounded-xl flex items-center flex-col gap-y-6 px-4 py-8">
            <div
              class="bg-[#EDFFED] text-primary-green text-lg rounded flex items-center justify-center p-3"
            >
              <span
                class="iconify"
                data-icon="mdi:clock-outline"
              ></span>
            </div>
            <div class="space-y-2">
                <p class="font-header-1 font-bold text-2xl">Self-Paced</p>
                <p class="text-[#898989] text-sm">Learn at your own time</p>
            </div>
          </div>
          <div class="cursor-pointer bg-white rounded-xl flex items-center flex-col gap-y-6 px-4 py-8">
            <div
              class="bg-[#EDFFED] text-primary-green text-lg rounded flex items-center justify-center p-3"
            >
              <span
                class="iconify"
                data-icon="heroicons-outline:desktop-computer"
              ></span>
            </div>
            <div class="space-y-2">
                <p class="font-header-1 font-bold text-2xl">Beginner-Focused</p>
                <p class="text-[#898989] text-sm">Learn any course from scratch</p>
            </div>
          </div>
          <div class="cursor-pointer bg-white rounded-xl flex items-center flex-col gap-y-6 px-4 py-8">
            <div
              class="bg-[#EDFFED] text-primary-green text-lg rounded flex items-center justify-center p-3"
            >
              <span
                class="iconify"
                data-icon="fluent:certificate-24-regular"
              ></span>
            </div>
            <div class="space-y-2">
                <p class="font-header-1 font-bold text-2xl">Certification</p>
                <p class="text-[#898989] text-sm">Earn a certificate of completion</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>