<template>
  <video-modal
    v-if="playExperience"
    @closemodal="playExperience = false"
    class="z-50"
  ></video-modal>
  <section class="bg-[#1A1F1A] pb-20 lg:pb-24 2xl:pb-10 pt-24 h-full xl:h-fit">
    <div class="grid grid-cols-1 lg:grid-cols-9 container">
      <div
        class="flex flex-col items-end md:items-center lg:items-start text-center lg:text-left pt-8 lg:pt-24 lg:col-span-4"
      >
        <h1
          class="font-header-1 text-white font-bold text-4xl px-10 lg:px-0 md:text-6xl place-items-center lg:w-10/12 2xl:w-8/12 leading-8 lg:leading-13 pt-0 md:pt-16 lg:pt-6"
        >
          Gain in-demand skills for
          <span class="lg:text-primary-green">free</span>
        </h1>
        <p
          class="text-white font-body my-7 text-sm lg:w-9/12 2xl:w-8/12 leading-6"
        >
          Join the Terra Learning Internship to learn and get the hands-on
          experience needed. Our interns are ready to take on entry-level and
          junior positions in just 8 weeks.
        </p>       

        <router-link
          :to="{ name: 'RegisterEmail' }"
          class="fixed bottom-0 left-0 right-0 z-20 md:relative min-w-full md:w-fit md:min-w-fit md:mb-36"
        >
          <PrimaryButton class="w-full md:w-fit !rounded-none md:!rounded mx-auto" id="register">
            {{ "Sign up for FREE" }}

          </PrimaryButton>
        </router-link>
        <div class="partners_section lg:space-x-5 hidden lg:block">
          <div class="lg:flex">
            <img
              src="@/assets/img/logo/jobberman.svg"
              alt="Jobberman"
              class="w-20 h-auto"
            />
          </div>
          <div class="lg:flex">
            <img
              src="@/assets/img/logo/raven-bank.svg"
              alt="Raven Bank"
              class="w-20 h-auto"
            />
          </div>
          <div class="">
            <img
              src="@/assets/img/logo/thire.svg"
              alt="Terra Hire"
              class="w-16 h-auto"
            />
          </div>
        </div>
      </div>
      <div class="hero_img_container lg:col-span-5 relative">
        <img
          fetchpriority="high"
          src="@/assets/img/img/hero-image_1.png"
          alt="Terra Learning"
          class="w-full lg:max-w-[800px] 2xl:max-w-[1000px] h-auto"
        />
        <p
          class="text-white font-body text-sm text-[0.8rem] lg:mb-28 2xl:mb-20 w-full lg:ml-20 text-center lg:text-left hidden lg:inline"
        >
          <span
            class="iconify text-primary-green inline text-center lg:text-left"
            data-icon="carbon:dot-mark"
          ></span
          >Over 300 thousand young Africans have started their journey into tech
          with us
        </p>
        <button
          @click="playExperience = true"
          class="font-header-1 text-white self-end justify-self-end lg:mt-auto flex items-center lg:absolute mt-5 mx-auto lg:-bottom-16 2xl:-bottom-5 lg:-right-10 text-lg"
        >
          <span
            class="iconify lg:text-3xl mr-2 lg:mr-4"
            data-icon="ant-design:play-circle-filled"
          ></span>
          Play to see Previous Experiences
        </button>
        <div class="partners_section2 lg:space-x-5 mt-14 lg:hidden bottom-0">
          <div class="lg:flex">
            <img
              src="@/assets/img/logo/jobberman.svg"
              alt="Jobberman"
              class="w-20 h-auto"
            />
          </div>
          <div class="lg:flex">
            <img
              src="@/assets/img/logo/raven-bank.svg"
              alt="Raven Bank"
              class="w-20 h-auto"
            />
          </div>
          <div class="">
            <img
              src="@/assets/img/logo/thire.svg"
              alt="Terra Hire"
              class="w-16 h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PrimaryButton from "./../inc/PrimaryButton.vue";
import AnnouncementComponent from "./../inc/AnnouncementComponent.vue";
import VideoModal from "./VideoModal.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      playExperience: false,
    };
  },
  components: {
    PrimaryButton,
    VideoModal,
    AnnouncementComponent,
  },
  computed: {
    ...mapGetters({
      settings: "auth/settings",
    }),
  },
};
</script>

<style lang="scss" scoped>
.hero_img_container {
  @apply items-center justify-center mt-2 lg:m-16 flex flex-col;
}
section {
  // background-image: url(./../../assets/img/bg/hero-bg-lines.svg);
  @apply bg-right-top bg-no-repeat bg-contain;
  .partners_section {
    div {
      @apply items-center justify-center;
      img {
        @apply cursor-pointer;
      }
    }
    @apply hidden lg:grid grid-cols-4 gap-4 content-around place-content-center;
  }
  .partners_section2 {
    div {
      @apply items-center justify-center;
      img {
        @apply cursor-pointer;
      }
    }
    @apply grid lg:hidden grid-flow-col gap-4 content-around place-content-center;
  }
}
</style>