import { createRouter, createWebHistory } from "vue-router";
import dashboardRoutes from "./dashboard";
import learningRoutes from "./lms";
import bootcampRoutes from "./bootcamp";

//

import IndexContainer from "@/views/IndexContainer.vue";
import Home from "@/views/landing/IndexPage.vue";
import IndexBootcamp from "@/views/landing/IndexBootcamp.vue";
import Privacy from "@/views/Privacy.vue";
import TermsAndConditions from "@/views/TermsAndCondition.vue";
import FrequentlyQuestions from "@/views/FrequentlyQuestions.vue";
import NotFound from "@/views/PageNotFound.vue";
import BlogPage from "@/views/blog/BlogPage.vue";
import CoursePage from "@/views/apply/CoursePage.vue";
import CareerRoadmap from "@/views/career/IndexPage.vue";

const routes = [
  {
    path: "/",
    component: IndexContainer,
    children: [
      {
        path: "",
        component: Home,
        name: "Home",
        meta: {
          title: "Home | Terra learning",
        },
      },
      {
        path: "/bootcamp",
        component: IndexBootcamp,
        name: "IndexBootcamp",
        meta: {
          title: "Bootcamp | Terra learning",
        },
      },
    ],
  },
  {
    path: "/privacy-policies",
    name: "Privacy",
    component: Privacy,
    meta: {
      title: "Privacy Policies | Terra learning",
    },
  },
  {
    path: "/career",
    name: "CareerRoadmap",
    component: CareerRoadmap,
    meta: {
      title: "Career Roadmap | Terra learning",
    },
  },
  {
    path: "/blog",
    name: "BlogPage",
    component: BlogPage,
    meta: {
      title: "Blog | Terra learning",
    },
  },
  {
    path: "/course",
    name: "ACoursePage",
    component: CoursePage,
    meta: {
      title: "Course | Terra learning",
    },
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: TermsAndConditions,
    meta: {
      title: "Privacy Policies | Terra learning",
    },
  },
  /**
   * Frequently Asked Questions
   */
  {
    path: "/questions",
    component: FrequentlyQuestions,
    name: "FrequentlyQuestions",
    meta: {
      title: "Frequently Asked Questions | Terra learning",
    },
  },

  ...dashboardRoutes,
  ...learningRoutes,
  ...bootcampRoutes,
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "Page Not Found",
    },
  },
];
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { 
        el: to.hash,
        behavior: 'smooth',
        top: 120,
       };
    }
    return { left: 0, top: 0 };
  },
});

export default router;
