<template>
  <div class="z-50 left-0 right-0 top-0 fixed w-full bg-[#1A1F1A]">
    <!-- flex justify-between items-center -->
    <nav
      class="grid grid-flow-col relative pt-4 pb-4 container place-items-center"
    >
      <router-link to="/" class="w-[130px] mr-auto">
        <img
          src="@/assets/img/logo/side-hustle-logo.svg"
          alt="Side Hustle Logo"
        />
      </router-link>
      <ul
        class="hidden lg:grid grid-cols-3 gap-x-12 place-content-center place-items-start font-body"
      >
        <router-link :to="{ name: 'IndexBootcamp' }">
          <li
            class="hover:text-primary-green transition-colors duration-200 ease-in-out"
          >
            Bootcamp
          </li>
        </router-link>
        <router-link :to="{ name: 'BlogPage' }">
          <li
            class="hover:text-primary-green transition-colors duration-200 ease-in-out"
          >
            Blog
          </li>
        </router-link>
      </ul>
      <div class="ml-auto flex lg:hidden justify-end items-center">
        <i
          @click="openNav = !openNav"
          :class="[openNav ? 'fa-close' : 'fa-bars']"
          class="dark:text-primary-green fa-solid text-primary-green text-lg"
        ></i>
      </div>

      <div
        class="hidden lg:flex items-center w-full justify-end col-span-4"
        v-if="!user"
      >
        <router-link :to="{ name: 'LoginPage' }">
          <button
            class="text-sm font-body-new text-white font-bold py-2.5 px-12 hover:text-primary-green"
          >
            Login
          </button>
        </router-link>
        <router-link
          :to="{ name: 'RegisterEmail' }"
          class="text-sm font-body-new text-white font-bold bg-primary-green border border-primary-green rounded-[5px] mx-4 py-2.5 px-8 hover:text-primary-green hover:bg-[#BCDEBA]"
        >
          Sign Up
        </router-link>
      </div>
      <div
        class="hidden lg:flex items-center w-full justify-end col-span-1"
        v-if="user"
      >
        <router-link
          :to="{ name: 'SelectBatchPage' }"
          class="text-sm font-body text-white bg-primary-green border border-primary-green rounded-[5px] mx-4 py-2.5 px-8 hover:text-white hover:bg-primary-green"
        >
          Dashboard
        </router-link>
      </div>
    </nav>
    <Transition name="slide-fade">
      <div
        v-show="openNav"
        class="p-0 lg:hidden bg-[#1A1F1A11] text-left pl-6 md:pl-28 pr-12 h-screen"
      >
        <div class="h-full">
          <ul class="relative space-y-4 text-caption text-[#ffffff] leading-12 h-full">
            <router-link :to="{ name: 'IndexBootcamp' }">
              <li>Bootcamp</li>
            </router-link>
            <router-link :to="{ name: 'BlogPage' }">
              <li>Blog</li>
            </router-link>
            <router-link :to="{ name: 'RegisterEmail' }" v-if="false">
              <li>
                <secondary-button class="text-white">
                  Learn For Free
                </secondary-button>
              </li>
            </router-link>
            <router-link :to="{ name: 'LoginPage' }" v-if="!user">
              <li>
                <primary-button class="!bg-white !text-primary-green my-4 text-left w-full"> Login </primary-button>
              </li>
            </router-link>
            <router-link :to="{ name: 'LoginPage' }" v-if="!user">
              <li>
                <primary-button class="text-white my-4 text-left w-full"> Sign Up For FREE </primary-button>
              </li>
            </router-link>
            <router-link :to="{ name: 'SelectBatchPage' }" v-if="user">
              <secondary-button class="text-white">
                Dashboard
              </secondary-button>
            </router-link>
          </ul>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import PrimaryButton from "./PrimaryButton.vue";
import SecondaryButton from "./SecondaryButton.vue";
import CourseModal from "./CourseModal.vue";
export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  components: {
    PrimaryButton,
    SecondaryButton,
    Popover,
    PopoverButton,
    PopoverPanel,
    CourseModal,
  },

  data() {
    return {
      changeNavColor: false,
      courses: null,
      showModal: false,
      openNav: false,
      openDropDown: false,
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 20) {
        this.changeNavColor = true;
      } else {
        this.changeNavColor = false;
      }
    },
    showOpen() {
      console.log(open);
    },
  },
  mounted() {
    axios
      .get("courses")
      .then((res) => {
        this.courses = res.data.payload;
      })
      .catch((error) => console.log(error));
  },
  watch: {
    $route() {
      this.openNav = false;
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  ul {
    li {
      @apply flex items-center text-white font-medium text-caption text-center justify-center cursor-pointer;
      .iconify {
        @apply ml-2;
      }
    }
  }
}

li {
  @apply cursor-pointer;
}

.newBg {
  @apply bg-[#1A1F1A] z-50;
}
.oldBg {
  @apply bg-transparent;
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
