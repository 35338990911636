<template>
  <section
    class="container min-h-screen bg-white grid gap-x-8 gap-y-8 pt-24 pb-12 md:grid-cols-2"
  >
    <div
      class="md:bg-[url(/src/assets/img/bg/star-bg.png)] bg-no-repeat bg-right-top flex flex-col justify-center gap-y-4"
    >
      <div
        class="flex gap-x-1 md:gap-x-4 items-center justify-center md:justify-start"
      >
        <div class="space-y-1">
          <img src="@/assets/img/img/five-stars.png" />
          <p class="text-[10px] md:text-xs font-medium font-poppins">
            Liked by 300,000+ students
          </p>
        </div>
        <img src="@/assets/img/img/avatars.png" class="h-8" />
      </div>
      <h1
        class="font-header-1 text-4xl leading-7 md:leading-10 text-center md:text-left md:text-6xl font-bold"
      >
        The Ultimate
        <br />
        <span class="text-primary-green">Career Roadmap</span>
        <br />
        for <span class="text-primary-green">Beginners</span>
      </h1>
      <p
        class="text-[#898989] font-body text-center md:text-left text-sm md:text-base w-full md:w-11/12"
      >
        A free step-by-step roadmap to help you break into tech with clarity and
        focus.
      </p>
      <div class="grid grid-flow-col gap-x-2 items-center my-2 md:mt-4 p-0">
        <img src="@/assets/img/logo/jobberman.svg" alt="Jobberman Logo" />
        <img src="@/assets/img/logo/raven-bank.svg" alt="Raven Bank Logo" />
        <img src="@/assets/img/logo/babangona.svg" alt="Babangona Logo" />
        <img src="@/assets/img/logo/thire.svg" alt="Terra Hire Logo" />
      </div>
    </div>
    <div class="relative h-fit">
      <div
        ref="movingDivContainer"
        class="absolute top-0 bottom-0 right-0 left-0 h-full w-full"
      >
        <div
          class="moving-div left-4 md:left-12 top-[5%] relative bg-white w-fit flex gap-x-2 justify-between rounded-lg px-2 md:px-4 py-1 text-[10px] md:text-xs font-semibold font-body"
        >
          <span
            class="iconify text-2xl text-primary-green inline-block absolute -top-2 -left-2"
            data-icon="solar:chart-square-bold"
          ></span>
          <div class="space-y-1">
            <p class="font-semibold font-body leading-3 whitespace-nowrap">
              Over 300,000+ beginners trained
            </p>
            <img
              class="h-5 md:h-6"
              src="@/assets/img/img/persons-frame.png"
              alt="Persons"
            />
          </div>
        </div>
        <div
          class="moving-div left-4 md:left-12 top-[40%] bg-white w-3/4 md:w-1/2 flex gap-x-2 justify-between rounded-lg px-2 py-1 md:py-2 text-xs font-semibold font-body h-fit"
        >
          <img
            class="h-8 w-8 rounded-full"
            src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/testimonies/vincent.png"
            alt="Vincent"
          />
          <div>
            <p class="text-[#6A6A6A] font-body text-[8px] leading-3">
              This was an excellent internship for someone with a strong desire
              but little experience in the field of customer service.
            </p>
            <span class="font-bold font-body text-[8px] md:text-[10px]"
              >Cohort 1.0</span
            >
          </div>
        </div>
        <div
          class="moving-div bottom-[5%] left-4 md:left-12 bg-white w-fit rounded-lg px-2 md:px-4 py-2 text-[10px] md:text-xs font-semibold font-body whitespace-nowrap"
        >
          <span
            class="iconify text-sm text-primary-green inline-block"
            data-icon="fluent:certificate-24-regular"
          ></span>
          Certificate
        </div>
        <div
          class="moving-div bottom-[20%] right-6 md:right-12 bg-white w-fit rounded-lg px-2 md:px-4 py-2 text-[10px] md:text-xs font-semibold font-body whitespace-nowrap"
        >
          <span
            class="iconify text-sm text-primary-green inline-block"
            data-icon="hugeicons:laptop-programming"
          ></span>
          Beginner-Friendly
        </div>
        <div
          class="moving-div right-6 md:right-12 bottom-[65%] bg-white w-fit rounded-lg px-2 md:px-4 py-2 text-[10px] md:text-xs font-semibold font-body whitespace-nowrap"
        >
          <span
            class="iconify text-sm text-primary-green inline-block"
            data-icon="solar:heart-bold"
          ></span>
          16+ code and no code skills 
        </div>
      </div>
      <img
        src="@/assets/img/img/career-hero-image.png"
        class="md:h-auto w-full rounded-2xl"
        alt="Hero Image"
      />
    </div>
  </section>
</template>


<script setup>
import { onMounted, onUnmounted, ref } from "vue";

const movingDivContainer = ref(null);
const movingDivs = ref([]);
const intervalIds = ref([]);

const getRandomPosition = () => {
  const containerRect = movingDivContainer.value.getBoundingClientRect();
  const divSize = 20; // Size of the moving div
  const maxX = containerRect.width - divSize;
  const maxY = containerRect.height - divSize;

  return {
    x: Math.floor(Math.random() * 100),
    y: Math.floor(Math.random() * 100),
  };
};

const moveDiv = (element) => {
  const { x, y } = getRandomPosition();
  // element.style.transform = `translate(${x}%, ${y}%)`;
  element.style.top = `${x}%`;
  element.style.left = `${y}%`;
};

const startMovingDivs = () => {
  const divs = document.querySelectorAll(".moving-div");
  movingDivs.value = divs;

  divs.forEach((element, index) => {
    const intervalId = setInterval(() => moveDiv(element), 2200);
    intervalIds.value.push(intervalId);
  });
};

const stopMovingDivs = () => {
  intervalIds.value.forEach((intervalId) => clearInterval(intervalId));
};

onMounted(() => {
  // startMovingDivs();
});

onUnmounted(() => {
  // stopMovingDivs();
});
</script>

<style scoped>
.moving-div {
  position: absolute;
  height: fit-content;
  /* transition: transform 1s ease; */
  transition: all 1s ease;
}
</style>