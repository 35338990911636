<template>
  <main class="">
    <h2
      class="font-bold text-2xl md:text-3xl font-header-3 dark:text-[#E1E1E1] text-body-text"
    >
      Slides: Resources to read
    </h2>
    <div class="flex md:flex-row flex-col py-4 gap-8" v-if="currentResource">
      <div
        class="w-full"
        v-if="
          currentDocs
            ?.filter((doc) => doc.week == week)
            .filter(
              (ite) => ite.modules[0].url.toLowerCase().endsWith('pptx') == true
            ).length > 0 ||
          currentDocs
            ?.filter((doc) => doc.week == week)
            .filter(
              (ite) => ite.modules[0].url.toLowerCase().endsWith('pptx') != true
            ).length > 0
        "
      >
        <!-- <div class="animate-pulse bg-gray-200 rounded-lg p-1">
                    <div class="h-4 bg-gray-300 rounded w-1/2"></div>
                    <div class="h-4 bg-gray-300 rounded w-4/5 my-4"></div>
                    <div class="h-4 bg-gray-300 rounded w-3/4"></div>
                </div> -->
        <AccordionComponentVue
          :items="currentDocs?.filter((doc) => doc.week == week)"
        />
        <template
          v-if="
            currentDocs
              ?.filter((doc) => doc.week == week)
              .filter(
                (ite) =>
                  ite.modules[0].url.toLowerCase().endsWith('pptx') != true
              ).length > 0
          "
        >
          <div
            class="border cursor-pointer border-[#60AAFD] bg-[#F1F7FE] rounded px-5 md:px-2 py-3 w-full md:w-3/4 mb-2"
            v-for="doc in currentDocs
              .filter((doc) => doc.week == week)
              .filter(
                (ite) =>
                  ite.modules[0].url.toLowerCase().endsWith('pptx') != true
              )"
            :key="doc.id"
          >
            <a
              :href="doc?.modules[0].url"
              target="_blank"
              class="md:text-sm text-[#211D12] mb-2 flex"
            >
              <span
                class="iconify text-2xl text-[#52A350]"
                data-icon="material-symbols:arrow-outward"
              ></span>
              <span class="font-body-new text-[12px] ml-3 text-[#211D12] mb-1">
                {{ doc?.title }}
              </span>
            </a>
            <!-- :href="doc?.modules[0].url" -->
            <!-- <div
              class="text-[10px] pl-9 text-[#52A350] w-64 overflow-hidden inlise-block whitespace-nowrap truncate"
              target="_blank"
            >
              {{ doc?.modules[0].url }}
            </div> -->
          </div>
        </template>
      </div>

      <div class="flex place-content-center w-full" v-else>
        <div class="block mt-28 dark:hidden">
          <img
            class="h-32 mx-auto"
            src="@/assets/img/emptyl.png"
            alt="Game icon"
          />
          <p class="text-xs mt-3 text-[#0C180C] text-center">
            No Resource available
          </p>
        </div>
        <div class="hidden mt-28 items-center dark:block">
          <img
            class="h-32 mx-auto"
            src="@/assets/img/empty.png"
            alt="Game icon"
          />
          <p class="text-[#E9E9E9] mt-3 text-xs text-center">
            No Resource available
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
// import { ref } from 'vue'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
// import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
// import SkeletonLoader from "@/components/lms/SkeletonLoader";

import { useStore } from "vuex";
import { onMounted, ref, watch, watchEffect } from "vue";
import { computed } from "@vue/reactivity";
import AccordionComponentVue from "../../../../components/lms/AccordionComponent.vue";
import { inject } from "vue";
import { encrypt, decrypt } from "@/helpers/encrypt";
// import swal from "sweetalert";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert";
const axios = inject("axios");
const store = useStore();
const router = useRouter();
const route = useRoute();
let id = ref(null);
let player = ref(null);
let question = ref("");
let resourceType = ref("video");
let showPlay = ref(true);

/**
 * Site Settings
 */
const siteSettings = computed(() => {
  return store.state.auth.settings;
});

const user = computed(() => {
  return store.state.auth.user;
});

const userCourse = computed(() => {
  return store.state.auth.userCourse;
});

/**
 * Current Lesson
 */
const currentLesson = computed(() => {
  return store.state.lms.currentLesson;
});

const currentDocs = computed(() => {
  return store.state.lms.lessonsDocs;
});

/**
 * Learning Type -- orientation || learning || jobberman
 */
const learningType = computed(() => {
  return store.state.lms.learningType;
});

/**
 * Learning Lessons
 */
const lessons = computed(() => {
  return store.state.lms.lessons;
});

/**
 * Jobberman Lessons
 */
const jobberman_lessons = computed(() => {
  return store.state.jobberman.lessons;
});

const week = computed(() => {
  return route.params.week ? decrypt(route.params.week) : null;
});

/**
 * Orientation Videos
 */
const orientation_videos = computed(() => {
  return store.state.lms.orientation_videos;
});

/**
 * Watched Learning Lessons
 */
const watchedLessons = computed(() => {
  return store.state.lms.watchedLessons;
});

/**
 * Jobberman wathced lessons
 */

const jobbermanWatchedLessons = computed(() => {
  return store.state.jobberman.watchedLessons;
});

/**
 *
 */
const jobbermanTests = computed(() => {
  return store.state.jobberman.checkTests;
});

const filterResources = computed(() => {
  if (currentLesson?.value?.modules) {
    return currentLesson.value.modules.filter((item) => {
      return item.format == resourceType.value;
    });
  } else if (currentLesson?.value) {
    return currentLesson.value;
  } else return null;
});

/**
 * Select the current resource to display to user
 * @param [Array] this.filterResources
 * @return object
 */
const currentResource = computed(() => {
  if (filterResources.value) {
    return filterResources.value[0] ?? filterResources.value;
  } else {
    return null;
  }
});

/**
 * This return the lessons for the week pass to it.
 * @params  week
 */
const eachWeekLessons = computed(() => {
  return (week) => lessons?.value?.filter((item) => item.week == week);
});

/**
 * show next button
 */
const showNextBtn = computed(() => {
  const currentWeekLessons = eachWeekLessons.value(currentLesson.value.week);
  let currentLessonIndex = currentWeekLessons?.findIndex(
    (item) => item.id == currentLesson.value.id
  );
  if (
    currentLessonIndex + 1 == currentWeekLessons?.length &&
    learningType?.value == "learning"
  ) {
    if (user?.value?.batch_id > 5) {
      return "Take Task";
    }
    return "Take Quiz";
  }
  if (currentLesson?.value) {
    return "Next";
  }
});

/**
 * Show Previous Button
 */
const showPreviousBtn = computed(() => {
  if (currentLesson.value) {
    if (learningType.value == "orientation") {
      return false;
    }
    return true;
  }
});

/**
 * Set Current learning material
 */
const setCurrentLesson = (lesson) =>
  store.dispatch("lms/setCurrentLesson", lesson);

/**
 * Store lessons
 */
const storeLessons = (payload) => store.dispatch("lms/storeLessons", payload);

/**
 * Save a watched lesson
 */
const saveWatchedLessons = (payload) =>
  store.dispatch("lms/saveWatchedLessons", payload);

const setLearningType = (learningType) => {
  store.dispatch("lms/setLearningType", learningType);
};

const fetchLessons = async (action) => {
  if (!lessons.value || action) {
    try {
      const { data } = await axios.get("auth/lessons");
      const payload = data.payload.lessons;
      // storeLessons(payload);

      if (action) {
        let newCurrentLesson = lessons.value.find(
          (item) => item.id == currentLesson.value.id
        );
        if (newCurrentLesson) {
          setCurrentLesson(newCurrentLesson);
        }
        return;
      }
      if (!currentLesson.value) {
        setCurrentLesson(payload[0]);
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    if (!currentLesson.value) {
      let lesson;
      if (
        orientation_videos?.value.length > 0 &&
        new Date() < new Date(userCourse?.value?.batch?.start_on)
      ) {
        lesson = orientation_videos.value[0];
        setLearningType("orientation");
      } else {
        lesson = lessons.value[0];
        setLearningType("learning");
      }
      setCurrentLesson(lesson);
    }
    return;
  }
};

const goToQuiz = (week) => {
  router.push({
    name: "QuizInstructionPage",
    params: { week: encrypt(week) },
  });
};

const goToTask = (week) => {
  router.push({
    name: "TaskInstructionPage",
    params: { week: encrypt(week) },
  });
};

const prevLesson = (id, week) => {
  if (learningType.value == "learning") {
    const currentWeekLessons = eachWeekLessons.value(week);
    let currentLessonIndex = currentWeekLessons.findIndex(
      (item) => item.id == id
    );

    if (currentLessonIndex > 0) {
      setCurrentLesson(currentWeekLessons[currentLessonIndex - 1]);
    }

    if (currentLessonIndex < 1 && week > 1) {
      const prevWeekLessons = eachWeekLessons.value(week - 1);
      setCurrentLesson(prevWeekLessons[prevWeekLessons?.length - 1]);
      setLearningType("learning");
      return;
    }
    if (currentLessonIndex == 0 && orientation_videos.value && week == 1) {
      setCurrentLesson(
        orientation_videos.value[orientation_videos.value.length - 1]
      );
      setLearningType("orientation");
    }
  }
  if (learningType.value == "orientation") {
    let currentLessonIndex = orientation_videos.value.findIndex(
      (item) => item.id == id
    );

    if (currentLessonIndex > 0) {
      setCurrentLesson(orientation_videos.value[currentLessonIndex - 1]);
    }
    if (currentLessonIndex == 0 && orientation_videos.value) {
      setCurrentLesson(
        orientation_videos.value[orientation_videos.value.length - 1]
      );
      setLearningType("orientation");
    }
  }
};

const nextLesson = async (id, week) => {
  const currentWeekLessons = eachWeekLessons.value(week);
  let currentLessonIndex = currentWeekLessons.findIndex(
    (item) => item.id == id
  );
  if (week == 4 && user?.value?.certificate_status?.status == false) {
    swal({
      title: "Oops!",
      text: "You are have to make payment for your certificate to access your week 4 learnings",
    });
    let week_3 = eachWeekLessons.value(3);
    setCurrentLesson(week_3[0], learningType);
    return;
  }
  if (learningType?.value == "orientation") {
    let lessonIndex = orientation_videos.value.findIndex(
      (item) => item.id == id
    );
    if (lessonIndex < orientation_videos?.length - 1) {
      setCurrentLesson(orientation_videos?.value?.[lessonIndex + 1]);
      setLearningType("orientation");
      return;
      // } else if (jobberman_lessons.value) {
      //     setCurrentLesson(jobberman_lessons?.value?.[0]);
      //     setLearningType('jobberman');
      //     return
    } else {
      const _weekOneLessons = eachWeekLessons.value(1);
      setCurrentLesson(_weekOneLessons?.[0]);
      setLearningType("learning");
    }
    return;
  }

  if (currentLessonIndex >= 0 && learningType?.value == "learning") {
    if (currentLessonIndex + 1 == currentWeekLessons.length) {
      const done = await saveLesson(
        currentLesson.value?.id,
        currentLesson.value?.week
      );
      if (done) {
        if (user?.value?.batch_id > 5) {
          goToTask(week);
          return;
        }
        goToQuiz(week);
        return;
      }
    }
    saveLesson(currentLesson.value.id, currentLesson.value.week);
    setCurrentLesson(currentWeekLessons[currentLessonIndex + 1]);
  }
};

/**
 * Save watch lessons
 */
const saveLesson = async (id, week) => {
  try {
    const payload = {
      lesson_id: id,
      week: week,
    };
    const { data } = await axios.post("auth/lessons/progress", payload);
    saveWatchedLessons(data.payload);
    return true;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Ask fetaured Question
 */
const askQuestion = async () => {
  try {
    const payload = {
      question: question.value,
    };
    const { data } = await axios.post(
      `auth/lessons/${currentLesson.value.id}/featured-questions`,
      payload
    );
    question.value = "";
    fetchLessons(true);
    swal({
      title: "Posted",
      text: data.payload,
    });
  } catch (error) {
    console.log(error);
  }
};

watch(id, () => {
  if (id.value) {
    if (lessons.value) {
      let lesson = lessons.value.find((item) => item.id == id.value);
      if (lesson) {
        setCurrentLesson(lesson);
      }
    }
  }
});

onMounted(() => {
  fetchLessons();
  if (route.params) {
    id.value = route.params.id;
  }
});
</script>

<style scoped></style>
