<template>
  <section id="download" class="my-24 container">
    <FormModal @close-modal="showModalForm=false" v-if="showModalForm === true" />
    <div
      class="container text-white flex gap-8 flex-col md:flex-row md:justify-between md:items-center bg-primary-green rounded-3xl p-8 shadow-[0px_21px_127px_22px_rgba(82,163,80,019]"
    >
      <div class="text-white font-body text-center md:text-left space-y-2">
        <h2 class="font-header-1 text-2xl md:text-3xl font-bold">
          Ready to get your free Career Roadmap?
        </h2>
        <p class="text-sm md:w-4/5">
          Download the free guide and take the first step toward your dream tech
          career
        </p>
      </div>
      <div class="flex flex-col items-center gap-y-6">
        <p class="font-body font-bold text-2xl md:text-xl">Get Now</p>
        <span
          class="iconify text-4xl animate-bounce"
          data-icon="ph:caret-double-down-bold"
        ></span>
        <button
            @click="showModalForm = true"
          class="bg-[#1A1F1A] border border-[#BBDDBB] rounded-lg font-bold font-body px-6 py-3"
        >
          Download My Free Guide
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import FormModal from "./FormModalComponent.vue";

const showModalForm = ref(false);
</script>

<style>
</style>