<template>
  <div
    @click="handleCloseModal"
    class="z-[99] bg-gray-300/50 h-screen fixed top-0 bottom-0 right-0 left-0 flex items-center justify-center p-4"
  >
    <div class="bg-white rounded-xl py-8 px-6 font-body" ref="modal_content">
      <h2 class="font-header-1 text-3xl text-center font-bold text-[#2F2F2F]">
        Get Your Free Career Roadmap
      </h2>
      <template v-if="submissionSuccess === false">
        <p class="font-body text-center text-[#898989] text-sm">
          Enter your details to download
        </p>

        <form
          autocomplete="off"
          @submit.prevent="submit"
          class="flex items-center flex-col"
        >
          <fieldset class="grid grid-flow-row gap-y-6 my-4 w-full">
            <input
              required
              class="border focus:border-primary-green border-[#E6E8EC] text-[#838E9E] text-sm placeholder:text-[#838E9E] rounded outline-none px-4 py-2"
              type="text"
              placeholder="Name"
              name="name"
              id="name"
              v-model="user.name"
            />
            <input
              required
              class="border focus:border-primary-green border-[#E6E8EC] text-[#838E9E] text-sm placeholder:text-[#838E9E] rounded outline-none px-4 py-2"
              type="email"
              placeholder="Enter email address"
              name="email"
              id="email"
              v-model="user.email"
            />
          </fieldset>
          <button
            type="submit"
            class="bg-primary-green py-2 px-4 text-white rounded-lg w-full mx-auto md:w-fit text-center"
          >
            Download
          </button>
        </form>
      </template>
      <template v-else>
        <div class="flex flex-col items-center justify-center gap-y-2">
          <img
            src="@/assets/img/icons/success.gif"
            alt="Success Image"
            class="h-48 text-center"
          />
          <p class="font-body text-center text-[#898989] text-sm">
            Congratulations! Your download should start shortly.
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { inject, reactive, ref } from "vue";

const $loading = inject("$loading");

const emit = defineEmits(["closemodal"]);

const modal_content = ref(null);
const submissionSuccess = ref(false);

const user = reactive({
  name: "",
  email: "",
});

const submit = async () => {
  const loader = $loading.show();
  try {
    const { data } = await axios.post("v2/career-guide/save", user);
    submissionSuccess.value = true;
    loader.hide();
    downloadFile(data.data.fileUrl);
} catch (error) {
    console.error(error);
    loader.hide();
  }
};

const downloadFile = async (url) => {
  console.log(url)
  let a = document.createElement("a");
  a.href = url;
  a.download = "Terra_Learning_Career_Guide_Document.pdf";
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(fileUrl)
  a.remove()
  // setTimeout(function () {
  //   document.body.removeChild(a);
  //   window.URL.revokeObjectURL(url);
  // }, 0);
  return;
};

const handleCloseModal = (event) => {
  const modalContent = modal_content.value;

  if (modalContent && !modalContent.contains(event.target)) {
    emit("close-modal");
  }
};
</script>

<style>
</style>