<template>
  <section class="container my-12" v-if="courses?.length > 0">
    <h2 class="font-bold text-center text-3xl md:text-5xl font-header-1">
      <span class="text-primary-green">Courses</span> We Offer
    </h2>
    <div class="grid gap-4 md:gap-8 grid-cols-2 md:grid-cols-4 my-12">
      <div
        v-for="course in courses"
        :key="course.id"
        class="bg-white rounded px-3 py-4 cursor-pointer hover:-translate-y-1 ease-linear duration-300 font-medium text-xs md:text-base text-center font-body flex items-center justify-center shadow-[0px_4px_30px_0px_rgba(79,79,79,0.07)] hover:shadow-[0px_8px_36px_0px_rgba(79,79,79,0.07)]"
      >
        {{ course.title }}
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const courses = computed(() => {
  return store.state.auth.courses;
});
</script>

<style>
</style>