<template>
  <div class="w-full md:w-3/4">
    <div
      v-for="(item, index) in items.filter(
        (ite) =>
          ite.modules[0].url.toLowerCase().endsWith('pptx') == true &&
          ite.modules[0].daily_motion_url != null
      )"
      :key="index"
      class="border border-gray-300 text-[#0C0C0C]'] dark:text-[#E5E5E5] rounded-lg mb-4"
    >
      <button
        class="grid grid-flow-col text-left px-4 gap-x-4 py-2 w-full"
        @click="activeIndex = activeIndex == index ? null : index"
      >
        <span class="">{{ item.title }}</span>
        <svg
          class="fill-current h-6 w-6 place-self-center justify-self-end"
          :class="{ 'rotate-180': activeIndex == index }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M10 13.333L5 8.333h3.333V5h3.334v3.333H15L10 13.333z" />
        </svg>
      </button>
      <!-- {{ item.modules[0] }} -->
      <div
        v-if="activeIndex == index"
        class="px-4 py-2 text-black relative overflow-hidden"
      >
        <!-- {{ item.modules[0] }} -->
        <!-- {{ item.modules[0].daily_motion_url }} -->
        <a :href="`${item.modules[0].url}`" :download="`${item.modules[0].url}`" class="text-primary-green font-semibold text-xs">
          <!-- line-md:download-loop -->
          <span class="iconify inline text-2xl" data-icon="line-md:download-loop"></span>
          Download Resource
        </a>
        <SlideEmbed :presentationId="`${item.modules[0].daily_motion_url}`" />
        <div
          class="absolute w-[160px] h-[50px] bg-[#E5E7E8] bottom-0 right-0 rounded-br-lg"
        ></div>
        <div
          class="absolute w-[160px] h-[50px] bg-transparent top-0 right-0 rounded-br-lg z-30"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import SlideEmbed from "./SlideEmbed.vue";
export default {
  name: "AccordionComponent",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    SlideEmbed,
  },
  data() {
    return {
      activeIndex: null,
    };
  },
};
</script>

<style>
.rotate-180 {
  transform: rotate(180deg);
}
</style>
