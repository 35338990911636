<template>
  <section
    class="font-body container bg-white py-12 bg-[url(/src/assets/img/bg/map-bg.png)] bg-cover bg-center bg-no-repeat"
  >
    <div
      class="grid grid-flow-row md:grid-cols-2 justify-between items-center gap-y-20 gap-x-20 lg:gap-x-32 my-8"
    >
      <div class="flex flex-col gap-y-12">
        <div class="text-center md:text-left">
          <h2
            class="font-bold text-3xl md:text-5xl font-header-1 leading-relaxed"
          >
            What You <span class="text-primary-green">Get</span>
          </h2>
          <p class="text-sm text-[#4D4D4D]">Take a look at what you'll get</p>
        </div>
        <div class="flex flex-col gap-y-4">
          <div
            class="text-[#474747] cursor-pointer hover:-translate-y-1 transition duration-200 ease-linear font-medium rounded-2xl space-x-2 p-4 bg-[#F8F8F8] shadow shadow-[#89C8871A] grid grid-flow-col gap-x-2 justify-start items-center align-middle text-sm md:text-base"
          >
            <span
              class="iconify text-sm text-primary-green inline-block"
              data-icon="streamline:check-square-solid"
            ></span>
            Career paths breakdown - find your perfect fit.
          </div>
          <div
            class="text-[#474747] cursor-pointer hover:-translate-y-1 transition duration-200 ease-linear font-medium rounded-2xl space-x-2 p-4 bg-[#F8F8F8] shadow shadow-[#89C8871A] grid grid-flow-col gap-x-2 justify-start items-center align-middle text-sm md:text-base"
          >
            <span
              class="iconify text-sm text-primary-green inline-block"
              data-icon="streamline:check-square-solid"
            ></span>
            Key skills and tools to master.
          </div>
          <div
            class="text-[#474747] cursor-pointer hover:-translate-y-1 transition duration-200 ease-linear font-medium rounded-2xl space-x-2 p-4 bg-[#F8F8F8] shadow shadow-[#89C8871A] grid grid-flow-col gap-x-2 justify-start items-center align-middle text-sm md:text-base"
          >
            <span
              class="iconify text-sm text-primary-green inline-block"
              data-icon="streamline:check-square-solid"
            ></span>
            How to prepare for the job market.
          </div>
          <div
            class="text-[#474747] cursor-pointer hover:-translate-y-1 transition duration-200 ease-linear font-medium rounded-2xl space-x-2 p-4 bg-[#F8F8F8] shadow shadow-[#89C8871A] grid grid-flow-col gap-x-2 justify-start items-center align-middle text-sm md:text-base"
          >
            <span
              class="iconify text-sm text-primary-green inline-block"
              data-icon="streamline:check-square-solid"
            ></span>
            A free self-assessment sheet.
          </div>
        </div>
      </div>
      <div class="font-body relative mx-2 h-56 md:h-72 md:w-4/5 md:mx-auto">
        <div
          class="border-t-2 border-primary-green w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <!-- Horizontal -->
        </div>
        <div
          class="border-l-2 border-primary-green h-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <!-- Vertical -->
        </div>
        <div
          class="absolute top-1/2 left-full -translate-x-3/4 md:-translate-x-1/2 -translate-y-1/2 border border-primary-green rounded-lg py-3 px-1 md:px-3 w-20 md:w-28 text-[10px] md:text-xs font-bold bg-white flex flex-col items-center gap-y-1"
        >
          <div
            class="p-1 rounded-md bg-[#EDFFED] flex items-center justify-center text-xl"
          >
            <span class="iconify" data-icon="mdi:clock-outline"></span>
          </div>
          <p>Self-Paced</p>
        </div>
        <div
          class="absolute top-1/2 left-0 -translate-x-1/4 md:-translate-x-1/2 -translate-y-1/2 border border-primary-green rounded-lg py-3 px-1 md:px-3 w-20 md:w-28 text-[10px] md:text-xs font-bold bg-white flex flex-col items-center gap-y-1"
        >
          <div
            class="p-1 rounded-md bg-[#EDFFED] flex items-center justify-center text-xl"
          >
            <span class="iconify" data-icon="solar:code-bold"></span>
          </div>
          <p>Engineering</p>
        </div>
        <div
          class="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-primary-green rounded-lg py-3 px-1 md:px-3 w-20 md:w-28 text-[10px] md:text-xs font-bold bg-white flex flex-col items-center gap-y-1"
        >
          <div
            class="p-1 rounded-md bg-[#EDFFED] flex items-center justify-center text-xl"
          >
            <span class="iconify" data-icon="carbon:product"></span>
          </div>
          <p>Product</p>
        </div>
        <div
          class="absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2 border border-primary-green rounded-lg py-3 px-1 md:px-3 w-20 md:w-28 text-[10px] md:text-xs font-bold bg-white flex flex-col items-center gap-y-1"
        >
          <div
            class="p-1 rounded-md bg-[#EDFFED] flex items-center justify-center text-xl"
          >
            <span class="iconify" data-icon="nimbus:marketing"></span>
          </div>
          <p>Marketing</p>
        </div>
        <div
          class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-primary-green rounded-lg p-2 md:p-3 w-fit text-[10px] md:text-xs font-bold bg-white"
        >
          <span
            class="iconify text-primary-green inline-block text-xl"
            data-icon="hugeicons:laptop-programming"
          ></span>
          12+ Video courses
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
</script>

<style>
</style>