<template>
  <nav class="fixed top-0 left-0 right-0 z-50 bg-white flex flex-col">
    <div :class="[openNav ? 'border-b border-[#B2B2B27D]' : '']" class="container flex justify-between py-2 md:py-4 h-fit">
      <router-link to="/">
        <img
          src="@/assets/img/logo/side-hustle-logo.svg"
          alt="Terra Learning Logo"
        />
      </router-link>
      <div class="hidden md:flex items-center font-body justify-end gap-x-4">
        <router-link
          :to="{ name: 'LoginPage' }"
          class="text-primary-green bg-white font-semibold rounded-lg text-sm px-4 py-2"
          >Login</router-link
        >
        <router-link
          :to="{ name: 'CareerRoadmap', hash: '#download' }"
          class="bg-primary-green text-white font-semibold rounded-lg text-sm px-4 py-2"
          >Download Career Roadmap</router-link
        >
      </div>
      <button
        @click="openNav = !openNav"
        class="flex md:hidden items-center font-body justify-end gap-x-4"
      >
      <i
          :class="[openNav ? 'fa-close' : 'fa-bars']"
          class="fa-solid text-primary-green text-lg"
        ></i>
      </button>
    </div>
    <Transition name="slide-fade">
      <div
        v-show="openNav"
        class="p-0 lg:hidden bg-white text-left pl-6 md:pl-28 pr-12 h-screen"
      >
        <div class="h-full">
          <div class="flex flex-col items-start font-body justify-end gap-y-6 my-12">
            <router-link
              :to="{ name: 'LoginPage' }"
              class="text-primary-green border-primary-green border-2 bg-white font-semibold rounded-lg text-sm px-4 py-2"
              >Login</router-link
            >
            <router-link
              @click="openNav = false"
              :to="{ name: 'CareerRoadmap', hash: '#download' }"
              class="bg-primary-green border-2 border-primary-green text-white font-semibold rounded-lg text-sm px-4 py-2"
              >Download Career Roadmap</router-link
            >
          </div>
        </div>
      </div>
    </Transition>
  </nav>
</template>

<script setup>
import { onBeforeMount, onMounted, reactive, ref } from "vue";

const openNav = ref(false);
</script>

<style>
</style>