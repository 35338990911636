<template>
  <div>
    <video ref="videoPlayer" class="video-js w-full h-96" controls preload="auto"
      poster="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/favicon.ico"
      data-setup='{ "playbackRates": [0.5, 1, 1.5, 2] }'>
      <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">
          supports HTML5 video
        </a>
      </p>
    </video>
  </div>
</template>

<script setup>
import 'video.js/dist/video-js.css'
import { ref, onMounted, watchEffect, watch } from 'vue';
import videojs from 'video.js';
import 'videojs-contrib-quality-levels';
import qalitySelectorHls from 'videojs-quality-selector-hls';

const props = defineProps({
  videoSrc: {
    String,
    default: 'https://terrahq.nyc3.cdn.digitaloceanspaces.com/lessons/hls/40/5205211155444.m3u8',
  }
});

const emit = defineEmits(["play", "ended"]);

const videoOptions = ref({
  autoplay: true,
  controls: true,
  sources: []
})

const videoPlayer = ref(null);
const player = ref(null);


watch(
  () => props.videoSrc,
  (src) => {
    if (player.value) {
      player.value.src(src);
    }
  }
)

onMounted(() => {
  videoOptions.value.sources = [
    {
      src: props.videoSrc,
    },
  ];
  // In case an error where qualitySelectorHls not found
  player.value = videojs(videoPlayer.value, videoOptions.value);
  player.value.qualitySelectorHls({
    displayCurrentQuality: true,
    placementIndex: 1,
    vjsIconClass: 'vjs-icon-hd'
  });
  player.value.on('ended', function () {
    emit('ended');
  });
  player.value.on('play', function () {
    emit('play');
  });
})
</script>